@import url(dcl8twq.css);

.section {
  width: "100%";
}

body {
  font-family: "source-sans-pro" !important;
  font-size: 16px !important;
}

.heading {
  font-family: "source-sans-pro" !important;
}

span.heading {
  border-bottom: 1px solid gray;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.gradient-btn,
.back,
.exit,
.edit-btn {
  /* background:linear-gradient(90deg, rgb(161, 27, 32) 0%, 8.08383%, rgb(179, 30, 35) 16.1677%, 20.6587%, rgb(195, 32, 38) 25.1497%, 26.9461%, rgb(201, 33, 39) 28.7425%, 31.7365%, rgb(209, 34, 40) 34.7305%, 38.024%, rgb(216, 35, 41) 41.3174%, 41.6168%, rgb(216, 35, 41) 41.9162%, 50.8982%, rgb(216, 35, 41) 59.8802%, 63.4731%, rgb(209, 34, 40) 67.0659%, 70.6587%, rgb(201, 33, 39) 74.2515%, 76.3473%, rgb(195, 32, 38) 78.4431%, 79.0419%, rgb(194, 32, 38) 79.6407%, 81.1377%, rgb(189, 31, 37) 82.6347%, 83.2335%, rgb(188, 31, 37) 83.8323%, 91.9162%, rgb(168, 28, 34) 100%)!important; */
  /* background:linear-gradient(-270deg, #FFB500 0%, #EE9223 100%)!important; */
  color: #fff !important;
}

.gradient-btn {
  color: #fff !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
  display: inline-block !important;
  outline: none !important;
  border-radius: 5px !important;
  border: none !important;
  /* background-color: #ef233c!important; */
  background-color: #9f004f !important;
  box-shadow: 0 3px 1px -2px #ccc, 0 2px 2px 0 #ccc, 0 1px 5px 0 #ccc !important;
  font-family: "Poppins" !important;
  /* font-size:15px!important; */
  font-weight: 500 !important;
  border: 1px solid #ccc !important;
}

.back,
.exit {
  color: #fff !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
  display: inline-block !important;
  outline: none !important;
  border-radius: 5px !important;
  border: none !important;
  background-color: #6e7881!important; /* #000 */
  box-shadow: 0 3px 1px -2px #ccc, 0 2px 2px 0 #ccc, 0 1px 5px 0 #ccc !important;
  font-family: "Poppins" !important;
  /* font-size:15px!important; */
  font-weight: 500 !important;
  border: 1px solid #ccc !important;
}

.gradient-btn:hover,
.back:hover,
.exit:hover,
.edit-btn:hover,
.delete-btn:hover {
  box-shadow: 2px 2px 4px 1px #ccc !important;
  background-color: #fff !important;
  color: #9f004f !important;
  border: 1px solid #ccc !important;
}

.back:hover,
.exit:hover,
.edit-btn:hover,
.delete-btn:hover {
  box-shadow: 2px 2px 4px 1px #ccc !important;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #ccc !important;
}

.edit-btn:hover,
.delete-btn:hover {
  box-shadow: 2px 2px 4px 1px #ccc !important;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #ccc !important;
}

.react-date-picker__wrapper {
  height: 38px;
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 80%) !important;

}

.date_invalid_border_color {
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-color: red !important;
}


.react-calendar__month-view__days__day--weekend {
  color: orangered !important;
}

.nav-tabs .nav-item {
  margin: 0 1px 0 0 !important;
}

.nav-tabs .nav-item .nav-link {
  background: #F7F7F7 !important;
  /* font-size: 14px!important; */
  font-weight: 700 !important;
  text-transform: uppercase !important;
  padding: 14px 25px !important;
  margin: 0 0 0px 0 !important;
  border-left: 1px solid lightgray !important;
  /* border-bottom: 1px solid lightgray!important; */
  position: relative !important;
  transition: all 0.3s ease-in-out !important;
  color: #000 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  color: #ffffff !important;
  background-color: #9f004f !important;
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
  border-bottom: 1px solid #6a0035 !important;
}

/* data picker css */


/* .gradient-btn {
    background: linear-gradient(90deg, rgb(161, 27, 32), transparent) rgb(168, 28, 34)!important;
    font-family: inherit!important;
    color: #fff!important;
    transition: background-color 1s!important;
}

.gradient-btn:hover, .gradient-btn:focus {
    background-color: #e91e63!important;
    color: #fff!important;
} */

.header-button:hover {
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}

.dangerMessageColor {
  color: red;
}

.normalMessageColor {
  color: rgb(0, 0, 146);
}

.cardBackground {
  background: #fff !important;
}

.btn {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
}

.header-button {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  font-family: "Poppins" !important;
  font-size: 12px !important;
}

.navbarContainer {
  font-size: 15px;
  z-index: 9997;
  /* border-bottom: 3px solid #999; */
  box-shadow: 1px 2px 6px 1px #ccc !important;
}

.card {
  box-shadow: 1px 2px 6px 1px #ccc !important;
}

.horizontalBar {
  border-bottom: 3px solid black;
}

.field {
  color: red;
}

.giftCardStyle {
  width: 30rem;
  height: auto;
  padding: 5px;
  background: "#fafafa";
}

.card {
  --bs-card-spacer-y: 1.5rem !important;
  --bs-card-spacer-x: 1.5rem !important;
}

a.link {
  color: #981040 !important;
}

a.link:hover {
  color: #000 !important;
}

/* 
 .my-swal2-icon {
    width:3em!important;
    height:3em!important;
 }

 .swal2-icon-content {
    font-size:2em!important;
 } */

.swal2-container {
  z-index: 20000 !important;
}

.gift-card-image {
  cursor: pointer;
}

.infoMessageColor {
  color: black;
  background-color: rgb(223, 250, 253) !important;
  border-radius: 5px !important;
  padding: 1%;
}

.icon-color {
  color: #333333;
}

.margin-removed {
  margin: 0;
  margin-right: 0;
}

.no-btn-border {
  border: 0px !important;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.navbar-nav a {
  font-size: 15px !important;
}

.navbar-nav>.nav-item.dropdown>.nav-link {
  border-bottom: 2px solid transparent;
}

.navbar-nav>.nav-item.dropdown>.nav-link:hover {
  border-bottom: 2px solid #9f004f !important;
}

.dropdown-menu {
  border-radius: 0 !important;
  /* border-top:2px solid #9f004f!important; */
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
}

.rdt_TableCol div,
.rdt_TableCol_Sortable div {
  white-space: normal !important;
  overflow: inherit !important;
}

.myTable thead th {
  cursor: pointer !important;
  vertical-align: top !important;
  font-size: 14px !important;
}

.myTable thead th,
.myTable td {
  padding: 5px !important;
}

.myTable {
  .-sort-desc {
    box-shadow: none !important;

    &:before {
      content: "▼";
      float: right;
    }
  }

  .-sort-asc {
    box-shadow: none !important;

    &:before {
      content: "▲";
      float: right;
    }
  }
}

.nav-item .dropdown>.dropdown-menu {
  top: 0 !important;
  left: 100% !important;
}

.nav-item .dropdown>.dropdown-menu {
  display: none !important;
}

.nav-item .dropdown:hover>.dropdown-menu {
  display: block !important
}

.dropdown-menu a {
  padding-left: 15px !important;
}

.nav-item .dropdown:hover {
  background-color: var(--bs-dropdown-link-hover-bg) !important;
}

a.nav-link {
  color: #000 !important;
  border-bottom: 2px solid transparent !important;
}

.navbar-nav>.nav-link:hover {
  border-bottom: 2px solid #9f004f !important;
}

.select_width {
  width: 110px;
}

.pointer {
  cursor: pointer!important;
}

.error {
  border: 1px solid red;
}

table.prepaid {
  border-collapse: collapse;
  width:100%;
}

table.prepaid th, table.prepaid td{
  border:1px solid lightgray;
  padding:5px;
}

table.prepaid th{
  border-bottom:2px solid gray!important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.edit_link_icon{
  margin: 0px 10px;
  font-size: 18px;
  color:grey;
}

.color-plus {
  color: #008a00 !important;
}

.color-minus {
  color: #B12704 !important;
}

.swal2-shown {
  overflow: unset !important;
  padding-right: 0px !important;
}

.footer-links a {
  margin:0px 20px;
}

.react-date-picker {
  display: inline-flex!important;
  position: absolute;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 0!important;
  flex-shrink: 0;
  border: thin solid gray;
}

.text-align-justify {
  text-align: justify;
}

.row-header {
  --bs-gutter-x: 1.5 rem !important;    
}

@media screen and (max-width: 320px) {
  .row-header {
    --bs-gutter-x: 0 rem !important;
  }
}

@media screen and (max-width: 991px) {
  .pl-25 {
    padding-left: 25px;
  }
}

@media screen and (max-width: 345px) {
  .pl-25 {
    padding-left: 0px;
  }
}

.m-p-0 {
  margin: 0 !important;
  padding: 0 !important;
}

.captcha {
  margin: 0 auto;
  width: 65%;
  margin-bottom: 1rem;
}

@media screen and (max-width: 480px) {
  .captcha {
      width: 70%;
  }
}

@media screen and (max-width: 425px) {
  .captcha {
      width: 80%;
  }
}

@media screen and (max-width: 375px) {
  .captcha {
      width: 93%;
  }
}

@media screen and (max-width: 375px) {
  .captcha {
      width: 100%;
      float: left;
  }
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0.6em !important;
}

.react-datepicker-wrapper{
  height: 36px !important;
}

.react-datepicker-wrapper input{
  width: 125px !important;
  padding: 5px 10px 5px 30px !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  -webkit-transition: all 100ms;
  transition: all 100ms;
}

.react-datepicker-wrapper input:focus {
  outline: none;
  width: 125px !important;
  padding: 5px 10px 5px 30px !important;
  border-color: #2684FF !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px #2684FF;
  -webkit-transition: all 100ms;
  transition: all 100ms;
}

.my-actions {
  margin: 2em 2em 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.left-gap {
  margin-left: auto;
}