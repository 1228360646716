@font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 900;
     font-display: swap;
     src: url("font/Montserrat.woff2") format('woff2');
     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
   }
   
   /* #notfound {
     position: relative;
     height: 100vh
   } */
   
   /* #notfound .notfound {
     position: absolute;
     left: 50%;
     top: 50%;
     -webkit-transform: translate(-50%,-50%);
     -ms-transform: translate(-50%,-50%);
     transform: translate(-50%,-50%)
   } */
   
   .notfound {
     max-width: 410px;
     width: 100%;
     text-align: center
   }
   
   .notfound .notfound-404 {
     height: 250px;
     position: relative;
     z-index: -1
   }
   
   .notfound .notfound-404 h1 {
     font-family: "montserrat",sans-serif;
     font-size: 200px;
     margin: 0;
     font-weight: 900;
     position: absolute;
     left: 50%;
     -webkit-transform: translateX(-50%);
     -ms-transform: translateX(-50%);
     transform: translateX(-50%);
     background: url("images/bg.jpg") no-repeat;
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     background-size: cover;
     background-position: center
   }
   
   .notfound h2 {
     font-family: "Poppins",sans-serif;
     color: #000;
     font-size: 24px;
     font-weight: 700;
     text-transform: uppercase;
     margin-top: 0
   }
   
   .notfound p {
     font-family: "Poppins",sans-serif;
     color: #000;
     font-size: 14px;
     font-weight: 400;
     margin-bottom: 20px;
     margin-top: 0
   }
   
   @media only screen and (max-width: 767px) {
     .notfound .notfound-404 {
         height:142px
     }
   
     .notfound .notfound-404 h1 {
         font-size: 112px
     }
   }